import {
  convertForumFacetsFilterToRequestParams,
  convertForumFacetsRequestParamsToFilter,
  shouldRequestCategoriesFacet,
} from '../forum';
import { getSearchResponse } from './getSearchResponse';
import { SearchParams } from './search';

export const getForumCategoriesFacetResponse = async (
  searchParams: SearchParams,
  correlationId?: string,
) => {
  const { searchRequest, searchSDK, previousSearchResponse } = searchParams;

  const filter = convertForumFacetsRequestParamsToFilter(searchRequest);

  if (!filter.categoryTitle) {
    return Promise.resolve(undefined);
  }

  if (shouldRequestCategoriesFacet(searchParams)) {
    return getSearchResponse(
      {
        ...searchRequest,
        ...convertForumFacetsFilterToRequestParams({
          contentType: filter.contentType,
        }),
        paging: {
          page: 0,
          pageSize: 0,
        },
      },
      false,
      searchSDK,
      correlationId,
    );
  } else {
    return previousSearchResponse;
  }
};
