import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';
import { AppSettings } from '@wix/search-settings-client';

import {
  IControllerConfig,
  ISearchResultsWixCode,
  ControllerFactory,
} from '../../../../lib/platform.types';
import { SearchResultsControllerStore } from './searchResultsControllerStore';

interface ISettingsEvents {
  previewStateChange: {
    shouldHaveSearchResults: boolean;
  };
  appSettingsChange: AppSettings;
}

export const searchResultsControllerFactory: ControllerFactory = async (
  params,
) => {
  const { config } = params;

  const eventHandler = createEventHandler<ISettingsEvents>(
    config.publicData.COMPONENT,
  );

  const controllerStore = new SearchResultsControllerStore(params);

  eventHandler.on(
    'previewStateChange',
    (value: ISettingsEvents['previewStateChange']) => {
      controllerStore.updateDemoMode(value);
    },
  );

  eventHandler.on('appSettingsChange', (appSettings: AppSettings) => {
    controllerStore.updateSettings(appSettings);
  });

  eventHandler.onReset(() => {
    controllerStore.updateDemoMode({
      shouldHaveSearchResults: true,
    });
  });

  return {
    pageReady() {
      return controllerStore.setInitialState();
    },

    updateConfig($w, updatedConfig) {
      const { publicData } = updatedConfig as IControllerConfig['config'];
      eventHandler.notify(publicData.COMPONENT); // Trigger events
      // To update widget in editor when settings change
      controllerStore.updateSettings();
    },

    exports: () =>
      ({
        changeQuery: controllerStore.changeQuery,
      } as ISearchResultsWixCode),
  };
};
