import {
  convertForumFacetsFilterToRequestParams,
  convertForumFacetsRequestParamsToFilter,
  shouldRequestContentTypeFacet,
} from '../forum';

import { getSearchResponse } from './getSearchResponse';
import { SearchParams } from './search';

export const getForumContentTypeFacetResponse = async (
  searchParams: SearchParams,
  correlationId?: string,
) => {
  const { searchRequest, searchSDK, previousSearchResponse } = searchParams;

  const filter = convertForumFacetsRequestParamsToFilter(searchRequest);

  if (!filter.contentType) {
    return Promise.resolve(undefined);
  }

  if (shouldRequestContentTypeFacet(searchParams)) {
    return getSearchResponse(
      {
        ...searchRequest,
        ...convertForumFacetsFilterToRequestParams(),
        paging: {
          page: 0,
          pageSize: 0,
        },
      },
      false,
      searchSDK,
      correlationId,
    );
  } else {
    return previousSearchResponse;
  }
};
